import React from "react"
import { CalculatorScreen } from "../components/calculator/calculator"

const Calculator = ({location}) => {

  return (
    <CalculatorScreen location={location} />
  )
}

export default Calculator